import devConfig from '../../aws-exports-dev';
import prodConfig from '../../aws-exports-prod';
import parisConfig from '../../aws-exports-paris';
import { KEY_LS_AWS_ACCOUNT, KEY_LS_AWS_CONFIG, KEY_LS_AWS_ENV } from '.';
import { AppRoutings } from '../enums/app-routings';

export const BODYGUARD = "bodyguard";

// key is the cloud environment id as provided by Cognito
const _SERVER_CONFIG = {
  '210750284236': {
    '0': {
      // prod Sydney
      bucket: 'prooftec-a2b-dd',
      config: prodConfig['210750284236'],
      s3url: 'https://prooftec-a2b-dd.s3-ap-southeast-2.amazonaws.com/',
      web: 'https://au.proofscan.prooftec.com/',
    },
    '1': {
      // prod Paris
      bucket: 'prooftec-prod-paris',
      config: parisConfig['210750284236'],
      s3url: 'https://prooftec-prod-paris.s3-eu-west-3.amazonaws.com/',
      web: 'https://eu.proofscan.prooftec.com/',
    },
    '2': {
      // dev
      bucket: 'prooftec-dev-dd',
      config: devConfig['210750284236'],
      s3url: 'https://prooftec-dev-dd.s3-ap-southeast-2.amazonaws.com/',
      web: 'http://dev.proofscan.prooftec.com:3000/',
    },
  },
  '590183810321': {
    '2': {
      // dev
      bucket: 'prooftec-imagery-dev',
      config: devConfig['590183810321'],
      s3url: 'https://prooftec-imagery-dev.s3-ap-southeast-2.amazonaws.com/',
      web: 'https://dev.proofscan.prooftec.com/',
      public_graphql: {
        endpoint:
          'https://3yiknmoehfgcbisc6kins3qtiq.appsync-api.ap-southeast-2.amazonaws.com/graphql',
        key: 'da2-i6teqrd4yvet7knbbsagjyti4e',
      },
    },
  },
  '211125761638': {
    '0': {
      // prod Sydney
      bucket: 'prooftec-imagery-syd',
      config: prodConfig['211125761638'],
      s3url: 'https://prooftec-imagery-syd.s3-ap-southeast-2.amazonaws.com/',
      web: 'https://au.proofscan.prooftec.com/',
      public_graphql: {
        endpoint:
          'https://f27bliewmbehxcztbvx7oq5be4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
        key: 'da2-bzsspjkdn5frdahlbccp2tebmy',
      },
    },
    '1': {
      // prod Paris
      bucket: 'prooftec-imagery-paris',
      config: parisConfig['211125761638'],
      s3url: 'https://prooftec-imagery-paris.s3-eu-west-3.amazonaws.com/',
      web: 'https://eu.proofscan.prooftec.com/',
      public_graphql: {
        endpoint: 'https://xjpea3x7vnbkbdes4lj4tegdju.appsync-api.eu-west-3.amazonaws.com/graphql',
        key: 'da2-4ufkag7asvcopieldzil25c53i',
      },
    },
    '2': {
      // dev
      bucket: 'prooftec-imagery-dev',
      config: devConfig['211125761638'],
      s3url: 'https://prooftec-dev-dd.s3-ap-southeast-2.amazonaws.com/',
      web: 'https://dev.proofscan.prooftec.com/',
    },
  },
};

/*
const _getCloudEnvironment = (): string => {
  const cloud = localStorage.getItem('CloudEnvId');
  return cloud ? cloud : '';
};
*/

export const getServerConfig = (label: string): string => {
  const account = localStorage.getItem(KEY_LS_AWS_ACCOUNT) ?? '';
  const cloud = localStorage.getItem(KEY_LS_AWS_ENV) ?? '';
  return _SERVER_CONFIG[account][cloud][label] ?? '';
};

export const getS3Prefix = (): string => {
  return getServerConfig('s3url');
};

export const getWebPrefix = (): string => {
  return getServerConfig('web');
};

export const getLocalHostPrefix = (): string => {
  const webPrefix = getWebPrefix();
  const debug = false;
  if (debug) {
    return webPrefix;
  }

  let result = webPrefix.split('/')[0];
  result += '//localhost';
  if (webPrefix.includes(':3000')) {
    result += ':3000';
  }
  result += '/';

  return result;
};

export const getS3ImageBucket = (source?: string): string => {
  let bucket: string = getServerConfig('bucket');
  if (source && source.length>0) {
    if (source==BODYGUARD) {
      const pos = bucket.lastIndexOf("-");
      if (pos>0) {
        bucket = bucket.slice(0,pos) + "-" + source + bucket.slice(pos);
      }
    }
  }
  return bucket;
};

export const getSSORedirect = (): string => {
  let result = getWebPrefix() + AppRoutings.SSO.substring(1);
  const strEnv = localStorage.getItem(KEY_LS_AWS_CONFIG);
  if (strEnv != null) {
    const envJson = JSON.parse(strEnv);
    if (envJson['sso_redirect_uri'] != null) {
      result = envJson['sso_redirect_uri'];
    }
  }

  return result;
};

/*
 * Env is an object that looks like this:
 * {
 *   "cloudenv": "2"
 * }
 * where the stringified number corresponds to the keys in SERVER_CONFIG
 */
export const getAWSConfig = (envJson) => {
  if (envJson === null || typeof envJson === 'undefined') {
    console.error('No aws config provided');
  }
  console.log('AWSConfig', envJson);
  const account = envJson && envJson['account'] ? envJson['account'] : '211125761638';
  const env = envJson && envJson['cloudenv'] ? envJson['cloudenv'] : '2';
  const config = _SERVER_CONFIG[account][env];
  console.log('Selected config', account, env, config);

  // To prevent confusion, the environemnt is checked at signin, defaulting to dev.
  localStorage.setItem(KEY_LS_AWS_ACCOUNT, account);
  localStorage.setItem(KEY_LS_AWS_ENV, env);

  return config ? config.config : devConfig;
};
