import { Tooltip } from 'antd';
import _ from 'lodash';
import { ReviewDateOrdering } from '../../backend/API';
import { IDropDownOption } from '../../components/DataTableFilters/dataTableFilters.model';
import {
  CID_AAMG_RENTAL,
  CID_ABSE,
  CID_DEMO,
  CID_DEMO_INSURANCE,
  CID_SOMPO,
  COMPANY_TYPES,
  getCid,
  getCountryCode,
  getMapForCid,
  getUserType,
  isDealer,
  isInsurance,
  isRepairer,
} from './customisation';
import { getS3Prefix, getWebPrefix } from './server_details';
import React from 'react';
import { COUNTRY_CODES } from './countries';
import { displayRego } from './rego';

const Messages = {
  FIELD_REQUIRED: '${label} is required!',
  NUMBER: '${label} is not a valid number',
  MIN: '${label} should be greater than or equal to ${min}',
  MAXLENGTH: '${label} has a maximum length of ${max} characters',
  INVALID: 'Please enter valid ${label}',
  INTEGER: '${label} is not a valid integer',
};

export const validateMessages = {
  required: Messages.FIELD_REQUIRED,
  types: {
    integer: Messages.INTEGER,
  },
  pattern: {
    mismatch: Messages.INVALID,
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_DATE_ONLY = 'YYYY-MM-DD';
export const DATE_FORMAT_TIME_ONLY = 'HH:mm';
export const DATE_FORMAT_SECONDS = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_HUMAN = 'DD-MMM-YY HH:mm';
export const DATE_FORMAT_AIR = 'DDMMMYY/HHmm';
export const CLAIMS_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const DATE_FORMAT_COMPACT = 'YYYYMMDDTHHmmss';

export const OVERVIEW_TAB_REVIEWS = 'Reviews';
export const OVERVIEW_TAB_DETAILS = 'Details';
export const OVERVIEW_TAB_DAMAGE_GROUPS = 'Damage Groups';
export const OVERVIEW_TAB_CAR_IMAGES = 'CarImages';
export const IMAGETYPE_EXTRA = 'EXTRA';
export const OVERVIEW_TAB_EXTRA = 'ExtraImages';
export const OVERVIEW_TAB_EXTRA_ACCESSORIES = 'ExtraImagesAccessories';
export const OVERVIEW_TAB_EXTRA_CLOSEUPS = 'ExtraImagesCloseups';

export const TAB_KEY_ALL = 'ALL';
export const TAB_KEY_BACK = 'BACK';
export const TAB_KEY_LEFT = 'LEFT';
export const TAB_KEY_FRONT = 'FRONT';
export const TAB_KEY_RIGHT = 'RIGHT';
export const TAB_KEY_FACES = [TAB_KEY_BACK, TAB_KEY_LEFT, TAB_KEY_FRONT, TAB_KEY_RIGHT];

export const ZOOM_AT_THE_TOP = 'zoom-at-top';
export const ZOOM_AT_THE_BOTTOM = 'zoom-at-bottom';

// Start: cognito information about user
export const KEY_COGNITO_ALLOWEDSITES = 'custom:AllowedSites';
export const SEPARATOR_COGNITO_ALLOWEDSITES = ';';
export const KEY_COGNITO_ADMIN = 'custom:AdminType';
export const KEY_COGNITO_USER = 'custom:UserType';
export const KEY_COGNITO_EMAIL = 'email';
// End: cognito information about user

// Config bitmask
// Not strictly a bitmask as there's options in here
export const CONFIG_BITMASK_SIDE_MENU = 1;
export const CONFIG_BITMASK_REVIEW_DISPLAY_CANDIDATES_ONLY = 2;
export const CONFIG_BITMASK_REVIEW_DISPLAY_SHOW_CARS = 4;
export const CONFIG_BITMASK_REVIEW_ORDER_SENTIMENT = 8;
export const CONFIG_BITMASK_REVIEW_ORDER_SPATIAL = 16;
// End: config bitmask

// Start: local storage utility
export const KEY_LS_ALLOWED_SITES = 'allowedsites';
export const KEY_LS_TARGET_URL = 'url_target';
export const KEY_LS_REFERRER = 'referrer';
export const KEY_LS_SIDE_MENU = 'sidemenu';
export const KEY_LS_CONFIG_BITMASK = 'config_bitmask';
export const KEY_LS_CID = 'cId';
export const KEY_LS_ADMINTYPE = 'adminType';
export const KEY_LS_USERTYPE = 'userType';
export const KEY_LS_COGNITOEMAIL = 'cognitoEmail';
export const KEY_LS_CLAIM_VEHICLE = 'claim_vehicle';
export const KEY_LS_USERNAME = 'username';
export const KEY_LS_LOGO_PREFIX = 'logo'; // cid and logo type need to be postfixed

export const KEY_LS_AWS_CONFIG = 'aws_config_env';
export const KEY_LS_AWS_ENV = 'CloudEnvId';
export const KEY_LS_AWS_ACCOUNT = 'CloudAccount';

export const KEY_LS_WORKSHOPS_CACHE = 'workshops_cache';
export const KEY_LS_WORKSHOPS_CACHE_LAST_UPDATE = 'lastupdate';
export const KEY_LS_WORKSHOPS_CACHE_DATA = 'data';
export const KEY_LS_WORKSHOPS_CACHE_CID = 'cid';
// End: local storage utility

// Start: search/query parameters
export const KEY_Q_TARGET = 'target';
export const KEY_Q_CONFIG = 'config';
export const KEY_Q_ACCOUNT = 'account';
export const KEY_Q_CID = 'cid';
// End: search/query parameters

// Start: Reviews Events
export enum ENUM_SCAN_TYPE {
  RENTAL,
  CLAIM,
}

export const sentimentsDropdown: IDropDownOption[] = [
  { id: 'INTERESTED', name: 'Interested' },
  { id: 'SUSPICIOUS', name: 'Suspicious' },
  { id: 'TROUBLED', name: 'Troubled' },
  { id: 'ALARMED', name: 'Alarmed' },
];

export const SENTIMENT_RANKING = {
  NONE: 0,
  INTERESTED: 1,
  SUSPICIOUS: 2,
  TROUBLED: 3,
  ALARMED: 4,
};

export const COLORS_SENTIMENT = {
  1: '#28a8cb', // Interested
  2: '#b628cb', // Troubled
  3: '#cb9e1b', // Suspicious
  4: '#c82227', // Alarmed
};

export const fuelDropdown: IDropDownOption[] = [
  { id: 'All', name: 'All' },
  { id: 'Full', name: 'Full' },
  { id: 'Not full', name: 'Not full' },
];

export const completeDropdown: IDropDownOption[] = [
  { id: 'N', name: 'Incomplete' },
  { id: 'Y', name: 'Complete' },
];

export const brandsDropdown: IDropDownOption[] = [
  { id: 'All Brand', name: 'All Brand' },
  { id: 'BMW', name: 'BMW' },
  { id: 'DAIHATSU', name: 'Daihatsu' },
  { id: 'HINO', name: 'Hino' },
  { id: 'ISUZU', name: 'Isuzu' },
  { id: 'KIA', name: 'Kia' },
  { id: 'MAZDA', name: 'Mazda' },
  { id: 'MERCEDES', name: 'Mercedes' },
  { id: 'MINI COOPER', name: 'Mini Cooper' },
  { id: 'MITSUBISHI', name: 'Mitsubishi' },
  { id: 'NISSAN', name: 'Nissan' },
  { id: 'SUZUKI', name: 'Suzuki' },
  { id: 'TOYOTA', name: 'Toyota' },
  { id: 'WULING', name: 'Wuling' },
  { id: 'Truck', name: 'Truck' },
];

const _MAPPING_REVIEW_STATUS_INT: any = {
  insurance: {
    0: 'sompo-in-progress',
    1: 'sompo-open',
    2: 'sompo-manager',
    3: 'sompo-approved',
    4: 'sompo-redo',
    5: 'sompo-closed',
  },
  dealer: {
    0: 'In progress',
    1: 'Pending confirmation',
    3: 'Images confirmed',
    4: 'Images rejected',
    5: 'Images rejected',
  },
  default: {
    0: 'In progress',
    1: 'Open',
    2: 'Escalated',
    5: 'Closed',
  },
};

const _MAPPING_REPORT_STATUS_INT: any = {
  default: {
    0: 'Draft',
    1: 'Preview',
    2: 'Finalised',
  },
};

// TODO: should this be generalised?
export const getMappingReviewStatusAll = () => {
  console.log('Mapping', isInsurance(), getCid(), getUserType());
  let mapping = _MAPPING_REVIEW_STATUS_INT['default'];
  if (isInsurance()) {
    mapping = _MAPPING_REVIEW_STATUS_INT['insurance'];
  }
  if (isDealer()) {
    mapping = _MAPPING_REVIEW_STATUS_INT['dealer'];
  }
  if (getCid() == CID_DEMO) {
    if (getUserType() == 11 || getUserType() == 102) {
      mapping = _MAPPING_REVIEW_STATUS_INT['dealer'];
    } else {
      mapping = _MAPPING_REVIEW_STATUS_INT['insurance'];
    }
  }
  return mapping;
};

export const getMappingReports = (): any => {
  const mapping = _MAPPING_REPORT_STATUS_INT['default'];
  return mapping;
};

// TODO: should this be generalised?
export const getMappingReviewStatusInt = (status: number) => {
  const mapping = getMappingReviewStatusAll();
  return mapping[status + ''];
};

// TODO: is this redundant?
export const displayReviewStatus = (status: string) => {
  const mapping = getMappingReviewStatusAll();
  const result = mapping[status];
  return result;
};

// TODO: CB-1396, currently for scansreviewlist only, but should be more general and configurable
const _convertMapToDropdown = (reviewStatusMap: any): IDropDownOption[] => {
  const result: IDropDownOption[] = [];
  Object.keys(reviewStatusMap).forEach((key) => {
    result.push({ id: key, name: reviewStatusMap[key] });
  });
  return result;
};
export const reviewStatusDropdown: IDropDownOption[] = _convertMapToDropdown(
  getMappingReviewStatusAll()
);

export const columnCharacteristics = {
  reviews: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
    hidden: isRepairer()
      ? [
          'fuel',
          'odometer',
          'rentalagreement',
          'signature',
          'reviewstatusint',
          'sitename',
          'sentiment',
        ]
      : [],
  },
  newpolicies: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
  },
  newclaims: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
  },
  scans: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
    hidden: ['reviewstatusint', 'make', 'model', 'eventtype'].concat(
      isDealer() || isRepairer()
        ? ['sentiment', 'fuel', 'odometer', 'notes', 'rentalagreement', 'signature']
        : []
    ),
  },
  scansreviewlist: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'statelicense', 'reviewstatusint'],
    hidden: [
      'sentiment',
      'fuel',
      'odometer',
      'notes',
      'rentalagreement',
      'signature',
      'make',
      'model',
    ],
    order: ['datetime', 'statelicense', 'reviewstatusint'],
  },
  comparisons: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
    hidden: ['reviewstatusint', 'make', 'model', 'eventtype'].concat(
      isDealer() || isRepairer()
        ? ['sentiment', 'fuel', 'odometer', 'notes', 'rentalagreement', 'signature']
        : []
    ),
  },
  comparisonsdealers: {
    sortable: {
      // TODO: enter sort fields
    },
    filterable: [], // TODO: enter filter fields, e.g. 'licensePlate'
    hidden: ['make', 'model'], // TODO: enter fields that should not be shown even though there is a renderer for it
  },
  comparisonreports: {
    sortable: {
      // TODO: enter sort fields
    },
    filterable: ['licensePlate'], // TODO: enter filter fields
    hidden: ['make', 'model'], // TODO: enter fields that should not be shown even though there is a renderer for it
  },
  capturecheck: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'sentiment', 'statelicense', 'fuel', 'rentalagreement'],
  },
  claims: {
    sortable: {
      datetime: { key: 'dateorder', default: ReviewDateOrdering.DESC },
      // TODO: enable when backend is fixed
      //sentiment: {key: 'sentimentorder'} // default: NONE
    },
    filterable: ['datetime', 'statelicense', 'rentalagreement'],
  },
  assets: {
    sortable: {
      statelicense: { key: 'assetidorder', default: 'ASC' },
    },
    filterable: [
      'statelicense',
      //'sitename', // TODO: hard coded to demo_
      'lastseen',
    ],
  },
  workshops: {
    sortable: {
      id: { key: 'workshopidorder', default: 'ASC' },
      currallocated: { key: 'allocatedorder', default: 'NONE' },
    },
    filterable: ['brandhandling', 'id', 'wilayah', 'iscomplete'],
  },
};

export const sortableLocationsColumns: string[] = ['code'];

export const sortableAssetsColumns = {
  statelicense: { key: 'statelicense', default: 'ASC' },
};

export const filterableAssetsColumns: string[] = ['statelicense', 'sitename', 'lastseen'];

export const FLAG_NOTES: any = {
  windscreen: { true: 2, false: 0 },
  delayed: { true: 1, false: 0 },
  serviceable: { true: 0, false: 2 },
  interior: { true: 1, false: 0 },
};

// End: Reviews Events

// Start: Assets
export const MAPPING_RENTAL_STATUS: any = {
  // TODO: would be nice to use actual enum values from API.ts
  CHECKEDIN: 'Returned',
  READYLINE: 'Ready line',
  CHECKOUT: 'On rent',
  UNAVAILABLE: 'Unavailable',
};

export const MAPPING_ASSET_CONDITION: any = {
  // TODO: would be nice to use actual enum values from API.ts
  DAMAGED: 'Damaged',
  UNDAMAGED: 'No damage',
};

// First key is CID, next key is user type number, value is user type description
export const MAPPING_USER_TYPES: any = {
  [CID_DEMO]: {
    0: 'Tester',
    1: 'Demo',
    11: 'Dealership',
    22: 'Annotator',
    23: 'QAer',
    [100 + COMPANY_TYPES.RENTAL]: 'ModeRental',
    [100 + COMPANY_TYPES.INSURANCE]: 'ModeInsurance',
    [100 + COMPANY_TYPES.DEALER]: 'ModeDealer',
    [100 + COMPANY_TYPES.REPAIRER]: 'ModeRepairer',
    [100 + COMPANY_TYPES.RENTAL_FINDER]: 'ModeRentalFinder',
  },
  [CID_ABSE]: {
    4: 'Manager',
    5: 'Counter',
  },
  [CID_SOMPO]: {
    0: 'Agent',
    1: 'Marketing',
    4: 'Claims',
    5: 'IT',
    // 2: reserved for admin
    // 3: reserved for temporary user
  },
  [CID_DEMO_INSURANCE]: {
    0: 'Agent',
    1: 'Marketing',
    4: 'Claims',
    5: 'Superuser',
    6: 'Support',
    // 2: reserved for admin
    // 3: reserved for temporary user
  },
};

export const MAPPING_CAR_TYPE: any = {
  0: 'Compact',
  1: 'Medium',
  2: 'Large',
  3: 'Premium',
  4: 'People carrier',
  5: 'SUV',
  6: '4WD',
  7: 'Van',
  8: 'Ute',
  9: 'Truck',
};
// End: Assets

export const MAPPING_WORKSHOP_TYPE: any = {
  [CID_SOMPO]: {
    0: 'Anchor',
    1: 'Primary',
    2: 'Authorized',
    3: 'General',
    4: 'Specialist',
  },
  [CID_DEMO_INSURANCE]: {
    0: 'Anchor',
    1: 'Primary',
    2: 'Authorized',
    3: 'General',
    4: 'Specialist',
  },
  [CID_AAMG_RENTAL]: {
    0: 'Carbiz',
    1: 'Avis',
    2: 'Europcar',
  },
};

export const convertToUrl = (s3_object_name: string, big_image = false) => {
  let result = s3_object_name;

  if (!s3_object_name.startsWith('http')) {
    result = getS3Prefix() + encodeURIComponent(s3_object_name);
  }
  if (big_image && result.slice(-4).toLowerCase() == '.jpg') {
    result = result.slice(0, -4) + '_big' + result.slice(-4);
  }
  return result;
};

export const numToAlphabet = (num: number) => {
  let s = '',
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
};

// Damage details screen: take first 12 damages
export const damageArraySize = 11;
export const damageArrayThreshold = 0;
export const damageOverviewZoom = 15; // Zoom factor, to be divided by 100

export const JURISIDICTION_PREFIX = 'RI-'; //TODO: currently Indonesia only, merge with BatchUpload

export const formatStateLicense = (stateLicense: string) => {
  if (isInsurance() && getCountryCode() == COUNTRY_CODES.INDONESIA) {
    // TODO: generalise this, to also include MVA-
    return stateLicense?.startsWith(JURISIDICTION_PREFIX)
      ? stateLicense?.split('-').pop()
      : stateLicense;
  }
  if (isDealer() || isRepairer()) {
    return displayRego(stateLicense, false);
  }

  return stateLicense?.startsWith('MVA-') ? stateLicense?.split('-').pop() : stateLicense;
};

export const setFuel = (fuel: string) => {
  return (
    <>
      {fuel === 'G8' ? (
        <div className="fuel-green">{fuel}</div>
      ) : (
        <div className="fuel-red">{fuel}</div>
      )}
    </>
  );
};

export const toThirdPartyImageURL = (eventId: string) => {
  let result = 'N/A';
  const web = getWebPrefix();
  if (eventId != null && eventId.length > 0 && web.length > 0) {
    const cid = getCid();
    result = web + 'im_view?event=' + encodeURIComponent(eventId) + (cid >= 0 ? '&cid=' + cid : '');
  }
  return result;
};

export const toThirdPartyPDFURL = (eventId: string) => {
  let result = 'N/A';
  const prefix = getS3Prefix();
  if (eventId != null && eventId.length > 0) {
    result = prefix + 'mobile/' + encodeURIComponent(eventId) + '/extraDocs/event.pdf';
  }
  return result;
};

export function getToolTip(text: string) {
  return (
    <Tooltip placement="right" trigger={['click', 'hover']} title={text}>
      <img
        className="icon-post-text"
        src={`${process.env.PUBLIC_URL}/assets/images/icon_info.svg`}
        alt=""
      />
    </Tooltip>
  );
}

const _getIconNotes = (level: number, tooltip: string) => {
  let icon = '';
  if (level === 0) icon = 'icon_notes_empty';
  else if (level === 1) icon = 'icon_notes_black';
  else if (level === 2) icon = 'icon_notes_red';
  // const icon = level === 0 ? 'icon_notes_empty' : level === 1 ? 'icon_notes_black' : 'icon_notes_red'
  return (
    <Tooltip
      placement="left"
      overlayStyle={{ whiteSpace: 'pre-line' }}
      color="#101010"
      title={tooltip}
    >
      <img src={`${process.env.PUBLIC_URL}/assets/images/${icon}.svg`} alt="" />
    </Tooltip>
  );
};

export const setNotesIcon = (notes: string, databaseField: string) => {
  if (getCid() == CID_DEMO_INSURANCE) {
    return ''; // TODO: workaround to avoid showing audit trail for demo purposes
  }

  const notesObj = JSON.parse(notes);
  let tooltip = '';
  let level = 0;

  if (databaseField == 'conditionProperties') {
    if (_.isArray(notesObj)) {
      notesObj.forEach((item: any) => {
        switch (item.name) {
          case 'agentid':
            tooltip = item.value + '\n' + tooltip;
            break;

          default:
            if (FLAG_NOTES[item.name]) {
              level =
                FLAG_NOTES[item.name][item.value] > level
                  ? FLAG_NOTES[item.name][item.value]
                  : level;
            } else {
              if (item.value && item.value.length > 0) {
                tooltip += item.name + ': ' + item.value + '\n';
                level = level === 0 ? 1 : level;
              }
            }
            if (item.comment) {
              tooltip += item.name + ': ' + item.comment + '\n';
              level = level === 0 ? 1 : level;
              // if (level === 0) {
              //     level = 1;
              // }
            }

            break;
        }
      });
    }
  }
  if (notesObj !== '' && databaseField == 'observations') {
    const objWorkflow = JSON.parse(notesObj); // TODO: why twice?
    const workflow = objWorkflow?.workflow ?? [];
    if (workflow.length > 0) {
      // Find the latest comment
      tooltip = '';
      for (let i = workflow.length - 1; tooltip.length == 0 && i >= 0; i--) {
        const comment = workflow[i]?.comment ?? '';
        if (comment.length > 0) {
          tooltip = (workflow[i]?.user ?? '') + ': ' + comment;
        }
      }
      if (tooltip.length > 0) {
        level = 2;
      }
    }
  }
  return _getIconNotes(level, tooltip);
};

export const MAPPING_DAMAGE_TYPE: any = {
  FAIRWEAR: 'Fairwear',
  IGNORE: 'Ignore',
  NEWDAMAGE: 'New Damage',
  PREEXISTING: 'Pre-existing',
  UNSURE: 'Unsure',
};

const _MAPPING_REVIEWS_EVENT_TYPE: any = {
  default: {
    CHECKOUT: 'Pick up',
    CHECKIN: 'Drop off',
  },
  17: {
    CHECKOUT: 'New business',
    CHECKIN: 'Claims',
  },
  insurance: {
    CHECKOUT: 'New policy',
    CHECKIN: 'Claims',
  },
  dealer: {
    CHECKOUT: 'Pick up',
    CHECKIN: 'Drop off',
  },
  repairer: {
    CHECKOUT: 'Pick up',
    CHECKIN: 'Drop off',
  },
  1: {
    CHECKOUT: 'Entering',
    CHECKIN: 'Exiting',
  },
};

export const getMappingReviewsEventType = (eventType: string): any => {
  const mapping = getMapForCid(_MAPPING_REVIEWS_EVENT_TYPE);
  return mapping[eventType];
};

export const MAPPING_FUEL: Array<string> = ['G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8'];

export const sortableClaimsColumns: string[] = [];
export const sortableRepairerColumns: string[] = ['recv_date', 'state'];

export const claimsStatusDropdown: IDropDownOption[] = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Quotes Requested' },
  { id: 2, name: 'Viewed Quote' },
  { id: 3, name: 'Quote Submitted' },
  { id: 4, name: 'Quote Viewed' },
  { id: 5, name: 'Requires Smart Assessment' },
  { id: 6, name: 'Quote Auto selected' },
];

export const ConfidenceValues = {
  0: 'Low',
  1: 'Medium',
  2: 'High',
};

// Used for downloading zip files as dropdown labels
export const DROPDOWN_DOWNLOAD_PHOTOS_QUALITY = 'High quality download photos';
export const DROPDOWN_DOWNLOAD_PHOTOS_FAST = 'Fast download photos';
